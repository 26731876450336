import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import TwoColumnLayout from "components/TwoColumnLayout/TwoColumnLayout"

const TTDTemplate = (props) =>  {
    const data = props.data.wpThingtodo
    const googleAdsSidebar = data.acfGoogleAdsSidebar

    return (
        <Layout 
            path={data.uri} 
            seo={data.seo} 
            hideSignup={data.acfFooterSettings.hideNewsletterSignUp}
            hideSocial={data.acfFooterSettings.hideSocialFollowUs}
        >
            <TwoColumnLayout 
                data={data} 
                googleAdsSidebar={googleAdsSidebar} 
                breadcrumbs={true} 
                pageTitle={data.title} 
            />
        </Layout>
    )
}

export const pageQuery = graphql`
query($id: String!) {
    wpThingtodo(id: {eq: $id}) {
        id
        title
        uri
        nodeType
        seo {
            metaKeywords
            metaDesc
            title
            breadcrumbs {
                text
                url
            }
        }
        ...thingToDoTemplateQuery
        acfFooterSettings {
            hideNewsletterSignUp
            hideSocialFollowUs
        }
        ttdCategories {
            nodes {
                acfCategoryIcon {
                    icon {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 80, quality: 85, placeholder: NONE, layout: CONSTRAINED)
                            }
                        }
                    }
                }
            }
        }
    }
}
`

export default TTDTemplate